<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels
          style="width: 800px; border: 0px; margin: 0 auto"
          :class="$vuetify.breakpoint.smAndUp ? 'mt-6' : 'pa-7'"
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="text-center">
              <div>
                <span style="font-size: 16px; font-weight: bold">
                  ขอรับรองว่า ฉันไม่ได้ซื้อลอตเตอรี่ในสถานศึกษา และ
                  ปัจจุบันฉันมีอายุครบ 20 ปี บริบูรณ์ (นโยบายและเงื่อนไข)
                </span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <b>นโยบายและเงื่อนไข</b>
              <br />
              <br />
              คำนิยาม เว้นแต่จะได้ระบุไว้เป็นอย่างอื่นเป็นการเฉพาะ
              ในข้อตกลงฉบับนี้ให้ถ้อยคำดังต่อไปนี้ตามที่อาจมีการนิยาม แก้ไข
              และ/หรือ เพิ่มเติม ในภายหลังโดยกฎหมาย กฎระเบียบ และ/หรือ
              เอกสารการเปิดเผยข้อมูลอื่น ๆ มีความหมาย ดังนี้ “ลูกค้า”หมายถึง
              ผู้ซื้อ ผู้ขอใช้บริการ ผู้ใช้บริการ สมาชิก
              ตลอดจนผู้มีส่วนเกี่ยวข้อง “บริษัท”หมายถึง www.rachachoklottery.com
              / ร้านราชาโชค “ลอตเตอรี่”หมายถึง
              สลากกินแบ่งรัฐบาลที่ออกโดยสำนักงานสลากกินแบ่งรัฐบาลทุกประเภท
              “แพลตฟอร์ม”หมายถึง “https://www.rachachoklottery.com” หรือ “
              https://rachachoklottery.com ” หรือ “แอปพลิเคชัน
              www.rachachoklottery.com” ซึ่งเป็นบริษัทผู้ให้บริการจำหน่าย
              รับจัดเก็บ รับฝาก รับขึ้นเงิน ลอตเตอรี่ ตลอดจนประกอบกิจการอื่น ๆ
              ตามวัตถุประสงค์ของบริษัท “ธุรกรรมซื้อขายสลาก”หมายถึง
              การซื้อขายลอตเตอรี่ “เช่าตู้เซฟ”หมายถึง
              การให้บริการเช่าพื้นที่จัดเก็บข้อมูลทางอิเล็กทรอนิกส์(Cloud)
              และให้หมายความรวมถึงค่าแสกน ค่าค้นหา ตลอดจนค่าดำเนินการต่าง ๆ
              ที่เกี่ยวข้องกับการซื้อขายลอตเตอรี่
              แบบเหมาจ่ายเพื่อประโยชน์สูงสุดของลูกค้า
              “ข้อตกลงและเงื่อนไขการใช้บริการ”หมายถึง
              ข้อตกลงและเงื่อนไขสำหรับการใช้บริการแพลตฟอร์มและแอปพลิเคชันตามเงื่อนไขที่บริษัทกำหนด
              “มัลแวร์”หมายถึง
              ซอฟต์แวร์ทุกรูปแบบซึ่งแทรกแซงการใช้แพลตฟอร์มให้บริการในลักษณะใด ๆ
              หรือรบกวนการใช้งานคอมพิวเตอร์ ซึ่งรวมถึงไวรัส การโจมตีทางไซเบอร์
              อีเมลขยะ (phishing e-mail) สปายแวร์ (spyware) เวิร์ม (worm)
              ลอจิกซอฟต์แวร์ (logic) บอมบ์ (bombs) โทรจันฮอร์ส (trojan horse)
              หรือองค์ประกอบที่เป็นอันตรายในลักษณะเดียวกันอื่น ๆ
              “เหตุสุดวิสัย”หมายถึง เหตุการณ์ที่อยู่นอกเหนือการควบคุมของบริษัท
              ซึ่งรวมถึง (ก) เหตุธรรมชาติ (ข) อุทกภัย อัคคีภัย แผ่นดินไหว
              การระเบิด โรคระบาด หรือภัยธรรมชาติอื่นใด (ค)
              การกระทำของศัตรูระดับประเทศ สงคราม การรุกราน ความเป็นปรปักษ์
              (ไม่ว่าจะมีการประกาศสงครามหรือไม่ก็ตาม)
              ภัยคุกคามหรือการกระทำของผู้ก่อการร้าย กบฏ การจลาจล การโจรกรรม
              การทุจริต การขาดแคลนแรงงาน การนัดหยุดงาน
              การกระทำของรัฐบาลหรือการก่อความไม่สงบอื่น ๆ (ง)
              ความล้มเหลวหรือความขัดข้องของอุปกรณ์การส่ง
              อุปกรณ์การสื่อสารหรืออุปกรณ์คอมพิวเตอร์
              หรือความขัดข้องของกระแสไฟฟ้า
              หรือความขัดข้องของอุปกรณ์อิเล็กทรอนิกส์หรืออุปกรณ์การสื่อสาร และ
              (จ) ไวรัสหรือการโจรกรรมข้อมูล
              ที่อยู่เหนือการควบคุมและป้องกันของบริษัท และการกระทำ เหตุการณ์
              หรือสถานการณ์ใด ๆ
              ซึ่งบริษัทเห็นว่าเป็นเหตุให้การธุรกรรมซื้อขายสลากหรือการให้บริการอื่น
              ๆ
              บนแพลตฟอร์มหรือแอปพลิเคชันระหว่างผู้ขอใช้บริการและบริษัทไม่อยู่ในภาวะปกติ
              เช่น การพักหรือปิดทำการของบริษัท หรือเหตุขัดข้องในการออกลอตเตอรี่
              เป็นต้น “เลขเดี่ยว” หมายถึง
              การซื้อสลากกินแบ่งรัฐบาลหรือสลากการกุศลจำนวนหนึ่งฉบับ “เลขชุด”
              หมายถึง
              การซื้อสลากกินแบ่งรัฐบาลหรือสลากการกุศลที่มีหมายเลขประจำสลากตรงกันทั้งหกหลักและมีจำนวนมากกว่าหนึ่งฉบับ
              <br />
              <br />
              <b>ข้อตกลงและเงื่อนไขการใช้บริการ</b>
              <br />
              เงื่อนไขทั่วไป บริษัทจำหน่ายลอตเตอรี่ราคา ฉบับละ 80 บาท
              บริษัทไม่มีนโยบายจำหน่ายลอตเตอรี่เกินกว่าราคาที่กำหนด
              ลูกค้าต้องยืนยันตัวตนด้วยข้อมูลอันเป็นจริงและต้องมีอายุไม่ต่ำกว่า
              20 ปี บริบูรณ์
              หากฝ่าฝืนบริษัทจะดำเนินคดีตามกฎหมายจนกว่าคดีจะถึงที่สุด ขณะซื้อ
              ลูกค้าต้องไม่ทำรายการสั่งซื้อภายในโรงเรียน หรือสถานศึกษา
              หรือสถานที่ที่ห้ามซื้อขายลอตเตอรี่
              ลอตเตอรี่ทุกฉบับที่ลูกค้าทำการซื้อผ่านแพลตฟอร์ม บริษัทรับรองว่า
              มีต้นฉบับจริงและเก็บรักษาไว้ ณ ที่ทำการของบริษัททุกฉบับ
              เว้นแต่เป็นกรณีสุดวิสัย กรรมสิทธิ์ในลอตเตอรี่ตกเป็นของลูกค้า
              เมื่อลูกค้าได้ชำระเงินและปฏิบัติตามเงื่อนไขที่บริษัทกำหนดสำเร็จแล้ว
              บริษัทขอสงวนสิทธิ์ในการจำหน่ายลอตเตอรี่ที่ได้เลือกไว้ในระบบก่อนหน้านี้
              ให้กับลูกค้าท่านอื่นหากท่านไม่ได้ทำการชำระเงิน
              ครบตามจำนวนภายในเวลาที่ระบบกำหนด
              ลอตเตอรี่ที่ไม่ถูกรางวัลและลูกค้าไม่ได้ขอรับต้นฉบับลอตเตอรี่ไว้ตามเงื่อนไข
              ลูกค้ายินยอมให้กรรมสิทธิ์ในลอตเตอรี่ที่ไม่ถูกรางวัลตกเป็นของบริษัทเพื่อเป็นข้อมูลสำหรับการตรวจสอบจากหน่วยงานที่เกี่ยวข้องต่อไป
              ห้ามลูกค้าทำการซื้อด้วยวิธีการหรือพฤติกรรมฝ่าฝืนกฎหมาย
              หรือใช้วิธีการฉ้อฉล หลอกลวงปิดบังข้อความจริงอันควรจะบอก
              หรือการทำผิดระเบียบหรือข้อบังคับต่าง ๆ
              ของบริษัทลูกค้าต้องทำการซื้อเพื่อตนเองและห้ามนำจำหน่ายต่อลูกค้าต้องปฏิบัติตามขั้นตอนเงื่อนไขในการซื้อลอตเตอรี่ตามที่บริษัทกำหนดที่มีอยู่ในขณะสมัครสมาชิกและที่จะมีขึ้นในภายหน้า
              หากลูกค้าไม่ปฏิบัติตามจนก่อให้เกิดความเสียหายแก่บริษัทบริษัทขอสงวนสิทธิ์ในการเรียกร้องค่าเสียหายจากลูกค้าตามกฎหมายต่อไป
              ลูกค้าต้องชำระราคาลอตเตอรี่ภายในระยะเวลาที่บริษัทกำหนดเมื่อลูกค้าชำระเงินแล้วต้องดำเนินการอัปโหลดหลักฐานการชำระเงินเข้าระบบภายในเวลาที่ระบบกำหนด
              มิฉะนั้นบริษัทจะดำเนินการยกเลิกคำสั่งซื้อดังกล่าวโดยไม่ต้องแจ้งให้ลูกค้าทราบล่วงหน้า
              ลูกค้าต้องตรวจสอบผลการสั่งซื้อหลังจากที่ทำรายการเสร็จสิ้น
              หากมีข้อผิดพลาดลูกค้าต้องแจ้งกลับมายังบริษัทในทันที
              มิฉะนั้นบริษัทจะไม่รับผิดในความเสียหายที่เกิดขึ้น(ถ้ามี)
              กรณีที่ลูกค้าอัปโหลดหลักฐานการชำระเงินที่ไม่ตรงกับคำสั่งซื้อและมีเจตนาทุจริตหรือเมื่อบริษัทตรวจสอบแล้วพบว่าคำสั่งซื้อ
              มีความผิดปกติอันอาจจะก่อให้เกิดความเสียหายแก่บริษัทหรือบุคคลอื่น
              บริษัทขอสงวนสิทธิ์และยกเลิกคำสั่งซื้อดังกล่าวในทันทีโดยไม่ต้องแจ้งให้ลูกค้าทราบล่วงหน้า
              พร้อมกับยกเลิกหรือปิดกั้นการใช้งานบัญชีดังกล่าวของลูกค้าและบริษัทจะใช้สิทธิ์ในการดำเนินคดีทั้งในทางแพ่งและในทางอาญาตามกฎหมายต่อไปจนกว่าคดีจะถึงที่สุด
              ลูกค้ารับว่า
              การซื้อขายลอตเตอรี่จากบริษัทเป็นสัญญาซื้อขายมีเงื่อนไขหรือเงื่อนเวลาบังคับไว้และกรรมสิทธิ์ในลอตเตอรี่จะยังไม่โอนไปยังลูกค้าและยังไม่ก่อให้เกิดสิทธิตามกฎหมายใด
              ๆ
              แก่ลูกค้าจนกว่าลูกค้าจะได้ปฏิบัติตามเงื่อนไขหรือข้อกำหนดของบริษัทครบถ้วนแล้วเท่านั้น
              บริษัทขอสงวนสิทธิ์ในการยกเลิก แก้ไข
              เปลี่ยนแปลงคำสั่งซื้อของลูกค้าทั้งที่เกิดขึ้นก่อนและภายหลังการประกาศรางวัล
              โดยมิต้องแจ้งให้ทราบล่วงหน้า คำสั่งซื้อจะสมบูรณ์
              เมื่อบริษัทได้ตรวจสอบข้อมูลและอนุมัติการซื้อเรียบร้อยเท่านั้น

              <br />
              <br />
              <b>เงื่อนไขกรณีฝาก</b>
              <br />
              กรณีที่ลูกค้าฝากและบริษัทได้รับฝากลอตเตอรี่ไว้กับบริษัทด้วยวิธีการใด
              ๆ
              ให้ถือว่าบริษัทมีหน้าที่เพียงผู้เก็บรักษาลอตเตอรี่ของลูกค้าไว้ด้วยวิธีการที่บริษัทกำหนดเท่านั้น
              การให้บริการเช่าตู้เซฟเพื่อใช้เป็นพื้นที่จัดเก็บข้อมูลทางอิเล็กทรอนิกส์(Cloud)
              ซึ่งลูกค้าสามารถนำข้อมูลคำสั่งซื้อ เช่น ภาพลอตเตอรี่
              หลักฐานการชำระเงิน ใบเสร็จค่าสินค้า ใบกำกับภาษี ฯลฯ
              จัดเก็บในตู้เซฟได้ เว้นแต่ลูกค้าได้นำข้อมูลคำสั่งซื้อ เช่น
              ภาพลอตเตอรี่ หลักฐานการชำระเงิน ใบเสร็จค่าสินค้า ใบกำกับภาษี
              เป็นต้น จัดเก็บในตู้เซฟให้สัญญาเช่าสิ้นสุดลง เวลา 24.00 น.
              ของวันที่มีการออกรางวัลในแต่ละงวดหรือตามเงื่อนไขที่บริษัทกำหนด
              เมื่อสัญญาเช่าสิ้นสุดลงข้อมูลในตู้เซฟจะถูกลบไปโดยอัตโนมัติหรือเป็นไปตามเงื่อนไขที่บริษัทกำหนด
              ก่อนสัญญาเช่าสิ้นสุดลงลูกค้าต้องดำเนินการสำรองข้อมูลด้วยตนเอง
              กรณีที่ลูกค้าฝากลอตเตอรี่ไว้กับบริษัทด้วยวิธีการใด ๆ
              ให้ถือว่าลูกค้ายินยอมและมอบหมายให้บริษัทเป็นตัวแทนไปขึ้นเงินรางวัลหรือจัดหาตัวแทนขึ้นเงินรางวัลหรือจัดหาผู้รับซื้อรางวัลหรือจัดหาผู้รับมอบอำนาจขึ้นเงินรางวัลหรือดำเนินการด้วยวิธีใด
              ๆ เกี่ยวกับการขึ้นเงินรางวัล ในการส่งมอบเงินรางวัลแก่ลูกค้า
              ลูกค้ายินยอมให้บริษัทนำเงินรางวัลโอนเข้าบัญชีที่ระบุไว้ภายในระยะเวลาไม่เกิน
              24 ชั่วโมง
              กรณีที่ลูกค้าประสงค์ให้บริษัทเป็นผู้ออกค่าอากรของลอตเตอรี่ที่ถูกรางวัลได้เฉพาะกรณีที่ลูกค้าฝากลอตเตอรี่ไว้กับบริษัทและเก็บลอตเตอรี่ที่ถูกรางวัลไว้ในตู้เซฟที่เช่าเท่านั้น
              และให้ถือว่าเงินค่าอากรดังกล่าวเป็นเงินได้ของประเภทและของปีภาษีเดียวกันกับเงินได้ที่บริษัทมีการออกเงินค่าอากรแทนให้
              ในกรณีลอตเตอรี่ของลูกค้าถูกรางวัล
              แต่ลูกค้ามีการระบุหมายเลขบัญชีเงินฝากผิดพลาดบริษัทจะไม่รับผิดชอบใด
              ๆ
              ในการติดตามเงินดังกล่าวคืนและให้ถือว่ามีการจ่ายเงินรางวัลให้แก่ลูกค้าแล้ว

              <br />
              <br />
              <b>อื่น ๆ</b>
              <br />
              บริษัทจะขอสงวนสิทธิ์ในการเปลี่ยนแปลงค่าบริการ
              โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
              ลูกค้าได้อ่านนโยบายและเงื่อนไขในการสั่งซื้อนี้โดยตลอดและเข้าใจดีแล้วยินยอมปฏิบัติตามเงื่อนไขข้างต้นทุกประการ
              และขอยืนยันการสั่งซื้อ
              ข้อมูลที่ลูกค้าได้ยืนยันการสั่งซื้อนี้จะถูกบันทึกเก็บไว้เป็นหลักฐานเพื่อการตรวจสอบและรายงานต่อหน่วยงานที่เกี่ยวข้อง
              ข้อกำหนดและเงื่อนไขยินยอมให้มีการเก็บ ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล (PDPA)
              ข้อกำหนดและเงื่อนไขฉบับนี้ ถือเป็นข้อตกลงระหว่าง
              www.rachachoklottery.com / ร้านราชาโชค กับผู้ซื้อหรือผู้รับบริการ
              “ผู้ซื้อ”หมายถึง ผู้ขอใช้บริการ ผู้ใช้บริการ สมาชิก
              ตลอดจนผู้มีส่วนเกี่ยวข้อง “บริการ” หมายถึง
              บริการของผู้ให้บริการภายใต้ข้อกำหนดและเงื่อนไขฉบับนี้
              “ผู้ให้บริการ”หมายถึง www.rachachoklottery.com / ร้านราชาโชค
              “บริษัทฯ”หมายถึง www.rachachoklottery.com / ร้านราชาโชค
              “สลากกินแบ่งรัฐบาล”หมายถึง
              สลากกินแบ่งรัฐบาลที่ออกโดยสำนักงานสลากกินแบ่งรัฐบาลทุกประเภท
              “แพลตฟอร์ม”หมายถึง “https://www.rachachoklottery.com” หรือ “
              https://rachachoklottery.com ” หรือ “แอปพลิเคชัน
              www.rachachoklottery.com” ซึ่งเป็นบริษัทฯ ผู้ให้บริการจำหน่าย
              รับจัดเก็บ รับฝาก
              รับขึ้นเงินสลากกินแบ่งรัฐบาลตลอดจนประกอบกิจการอื่น ๆ
              ตามวัตถุประสงค์ของบริษัท ผู้ซื้อยินยอมให้บริษัทฯ
              จัดเก็บข้อมูลส่วนบุคคล (Personal Data)
              ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลพ.ศ.2562 PDPA (Personal
              Data Protection Act B.E. 2562 (2019)) เพื่อมีไว้ใช้และเปิดเผย
              ซึ่งข้อมูลส่วนบุคคล ได้แก่ชื่อ-นามสกุล หรือชื่อเล่น /
              เลขประจำตัวประชาชน, เลขหนังสือเดินทาง, เลขบัตรประกันสังคม,
              เลขใบอนุญาตขับขี่,เลขประจำตัวผู้เสียภาษี, เลขบัญชีธนาคาร,
              เลขบัตรเครดิต (การเก็บเป็นภาพสำเนาบัตรประชาชนหรือสำเนาบัตรอื่น
              ๆที่มีข้อมูลส่วนบุคคลที่กล่าวมาย่อมสามารถใช้ระบุตัวบุคคลได้โดยตัวมันเอง
              จึงถือเป็นข้อมูลส่วนบุคคล) / ที่อยู่, อีเมล์,เลขโทรศัพท์ /
              ข้อมูลอุปกรณ์หรือเครื่องมือ เช่น IP address, MAC address, Cookie
              ID / ข้อมูลระบุทรัพย์สินของบุคคลเช่น ทะเบียนรถยนต์, โฉนดที่ดิน /
              ข้อมูลที่สามารถเชื่อมโยงไปยังข้อมูลข้างต้นได้ เช่น
              วันเกิดและสถานที่เกิด,เชื้อชาติ,สัญชาติ, น้ำหนัก, ส่วนสูง,
              ข้อมูลตำแหน่งที่อยู่ (location), ข้อมูลการแพทย์, ข้อมูลการศึกษา,
              ข้อมูลทางการเงิน,ข้อมูลการจ้างงาน /
              ข้อมูลหมายเลขอ้างอิงที่เก็บไว้ในไมโครฟิล์ม
              แม้ไม่สามารถระบุไปถึงตัวบุคคลได้แต่หากใช้ร่วมกับระบบดัชนีข้อมูลอีกระบบหนึ่งก็จะสามารถระบุไปถึงตัวบุคคลได้
              /ข้อมูลการประเมินผลการทำงานหรือความเห็นของนายจ้างต่อการทำงานของลูกจ้าง
              / ข้อมูลบันทึกต่าง ๆที่ใช้ติดตามตรวจสอบกิจกรรมต่าง ๆ ของบุคคล เช่น
              log file
              /ข้อมูลที่สามารถใช้ในการค้นหาข้อมูลส่วนบุคคลอื่นในอินเทอร์เน็ต
              ตลอดจนข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน(Sensitive Personal Data)
              ได้แก่ ข้อมูล เชื้อชาติ, เผ่าพันธุ์, ความคิดเห็นทางการเมือง,
              ความเชื่อในลัทธิศาสนาหรือปรัชญา, พฤติกรรมทางเพศ, ประวัติอาชญากรรม,
              ข้อมูลสุขภาพ ความพิการ หรือข้อมูลสุขภาพจิต,ข้อมูลสหภาพแรงงาน,
              ข้อมูลพันธุกรรม, ข้อมูลชีวภาพ, ข้อมูลทางชีวมิติ (Biometric) เช่น
              รูปภาพใบหน้า, ลายนิ้วมือ,ฟิล์มเอกซเรย์, ข้อมูลสแกนม่านตา,
              ข้อมูลอัตลักษณ์เสียง, ข้อมูลพันธุกรรม
              เป็นต้นและข้อมูลอื่นใดซึ่งกระทบต่อเจ้าของข้อมูลในทำนองเดียวกันตามที่คณะกรรมการประกาศกำหนด
              ข้าพเจ้าเข้าใจดีว่า ผู้ให้บริการ จะเก็บรวบรวม ใช้
              และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อวัตถุประสงค์ในการให้บริการตามสัญญานี้
              การวิเคราะห์ข้อมูลเพื่อวางแผนทางการตลาดการนำเสนอสินค้าและบริการอื่น
              ๆ ของผู้ให้บริการแก่ข้าพเจ้า รวมถึงวัตถุประสงค์อื่น ๆ
              ตามที่ผู้ให้บริการเห็นสมควร ข้าพเจ้ารับทราบดีว่า
              หากข้าพเจ้าไม่ตกลงยอมรับข้อกำหนดและเงื่อนไขนี้ผู้ให้บริการสงวนสิทธิ์ไม่ให้บริการแก่ข้าพเจ้าได้
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- <v-col cols="12" class="mt-n10">
        <v-expansion-panels
          style="width: 800px; border: 0px; margin: 0 auto"
          :class="$vuetify.breakpoint.smAndUp ? 'mt-6' : 'pa-7'"
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="text-center">
              <div>
                <span style="font-size: 16px; font-weight: bold">
                  ข้าพเจ้าเข้าใจดีว่า ผู้ให้บริการ จะเก็บรวบรวม ใช้
                  และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อวัตถุประสงค์ในการให้บริการตามสัญญานี้
                  การวิเคราะห์ข้อมูลเพื่อวางแผนทางการตลาดการนำเสนอสินค้าและบริการอื่นๆ
                  ของผู้ให้บริการแก่ข้าพเจ้า รวมถึงวัตถุประสงค์อื่นๆ
                  ตามที่ผู้ให้บริการเห็นสมควร
                </span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              ข้อกำหนดและเงื่อนไขฉบับนี้ ถือเป็นข้อตกลงระหว่าง
              www.rachachoklottery.com กับผู้ซื้อหรือผู้รับบริการ
              <br />
              <br />
              1. “ผู้ซื้อ” หมายถึง ผู้ขอใช้บริการ ผู้ใช้บริการ สมาชิก
              ตลอดจนผู้มีส่วนเกี่ยวข้อง
              <br />
              <br />
              2. “บริการ” หมายถึง
              บริการของผู้ให้บริการภายใต้ข้อกำหนดและเงื่อนไขฉบับนี้
              <br />
              <br />
              3.“ผู้ให้บริการ” หมายถึง www.rachachoklottery.com / ร้านราชาโชค
              <br />
              <br />
              4. “บริษัทฯ” หมายถึง www.rachachoklottery.com / ร้านราชาโชค
              <br />
              <br />
              5. “สลากกินแบ่งรัฐบาล” หมายถึง
              สลากกินแบ่งรัฐบาลที่ออกโดยสำนักงานสลากกินแบ่งรัฐบาลทุกประเภท
              <br />
              <br />
              6. “แพลตฟอร์ม” หมายถึง “https://www.rachachoklottery.com” หรือ “
              https://rachachoklottery.com ” หรือ
              “แอปพลิเคชันwww.rachachoklottery.com” ซึ่งเป็นบริษัทฯ
              ผู้ให้บริการจำหน่าย รับจัดเก็บ รับฝาก
              รับขึ้นเงินสลากกินแบ่งรัฐบาลตลอดจนประกอบกิจการอื่น ๆ
              ตามวัตถุประสงค์ของบริษัทฯ
              <br />
              <br />
              7. ผู้ซื้อยินยอมให้บริษัทฯ จัดเก็บข้อมูลส่วนบุคคล (Personal Data)
              ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลพ.ศ.2562 PDPA (Personal
              Data Protection Act B.E. 2562 (2019)) เพื่อมีไว้ใช้และเปิดเผย
              ซึ่งข้อมูลส่วนบุคคล ได้แก่ชื่อ-นามสกุล หรือชื่อเล่น /
              เลขประจำตัวประชาชน, เลขหนังสือเดินทาง, เลขบัตรประกันสังคม,
              เลขใบอนุญาตขับขี่,เลขประจำตัวผู้เสียภาษี, เลขบัญชีธนาคาร,
              เลขบัตรเครดิต (การเก็บเป็นภาพสำเนาบัตรประชาชนหรือสำเนาบัตรอื่น
              ๆที่มีข้อมูลส่วนบุคคลที่กล่าวมาย่อมสามารถใช้ระบุตัวบุคคลได้โดยตัวมันเอง
              จึงถือเป็นข้อมูลส่วนบุคคล) / ที่อยู่, อีเมล์,เลขโทรศัพท์ /
              ข้อมูลอุปกรณ์หรือเครื่องมือ เช่น IP address, MAC address, Cookie
              ID / ข้อมูลระบุทรัพย์สินของบุคคลเช่น ทะเบียนรถยนต์, โฉนดที่ดิน /
              ข้อมูลที่สามารถเชื่อมโยงไปยังข้อมูลข้างต้นได้ เช่น
              วันเกิดและสถานที่เกิด,เชื้อชาติ,สัญชาติ, น้ำหนัก, ส่วนสูง,
              ข้อมูลตำแหน่งที่อยู่ (location), ข้อมูลการแพทย์, ข้อมูลการศึกษา,
              ข้อมูลทางการเงิน,ข้อมูลการจ้างงาน /
              ข้อมูลหมายเลขอ้างอิงที่เก็บไว้ในไมโครฟิล์ม
              แม้ไม่สามารถระบุไปถึงตัวบุคคลได้แต่หากใช้ร่วมกับระบบดัชนีข้อมูลอีกระบบหนึ่งก็จะสามารถระบุไปถึงตัวบุคคลได้
              /ข้อมูลการประเมินผลการทำงานหรือความเห็นของนายจ้างต่อการทำงานของลูกจ้าง
              / ข้อมูลบันทึกต่าง ๆที่ใช้ติดตามตรวจสอบกิจกรรมต่าง ๆ ของบุคคล เช่น
              log file
              /ข้อมูลที่สามารถใช้ในการค้นหาข้อมูลส่วนบุคคลอื่นในอินเทอร์เน็ต
              ตลอดจนข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน(Sensitive Personal Data)
              ได้แก่ ข้อมูล เชื้อชาติ, เผ่าพันธุ์, ความคิดเห็นทางการเมือง,
              ความเชื่อในลัทธิศาสนาหรือปรัชญา, พฤติกรรมทางเพศ, ประวัติอาชญากรรม,
              ข้อมูลสุขภาพ ความพิการ หรือข้อมูลสุขภาพจิต,ข้อมูลสหภาพแรงงาน,
              ข้อมูลพันธุกรรม, ข้อมูลชีวภาพ, ข้อมูลทางชีวมิติ (Biometric) เช่น
              รูปภาพใบหน้า, ลายนิ้วมือ,ฟิล์มเอกซเรย์, ข้อมูลสแกนม่านตา,
              ข้อมูลอัตลักษณ์เสียง, ข้อมูลพันธุกรรม
              เป็นต้นและข้อมูลอื่นใดซึ่งกระทบต่อเจ้าของข้อมูลในทำนองเดียวกันตามที่คณะกรรมการประกาศกำหนด
              <br />
              <br />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col> -->

      <v-col
        cols="12"
        class="text-center mt-n10 mb-n6 pa-10"
        v-if="userData.user.socialId !== ''"
      >
        <v-btn
          class="custom-btn"
          style="background-color: #07bc0c"
          @click="goToLine()"
        >
          <span style="font-size: 16px; font-weight: bold; color: white"
            >รับการแจ้งเตือนผ่าน LINE
          </span>
          &emsp;
          <v-img
            src="../assets/logo/Line-Logo.png"
            max-width="20px"
            height="auto"
          ></v-img
        ></v-btn>
      </v-col>

      <v-col cols="12" class="mt-n8 pa-10">
        <v-card style="width: 800px; border: 0px; margin: 0 auto" class="pa-2"
          ><v-card-title
            ><span style="color: #4ca3ad">ข้อมูลส่วนตัวผู้ใช้</span>&emsp;
            <!-- <v-btn small color="#2e2e2e"
              ><v-icon size="16px" style="color: #fff"
                >mdi-pencil-outline</v-icon
              >
              <span style="color: #fff">แก้ไข</span>
            </v-btn> -->
          </v-card-title>
          <v-row class="pa-6">
            <v-col cols="12" class="text-center">
              <img
                src="../assets/logo/Logo1.jpg"
                alt="Profile"
                class="responsive-img"
              />
            </v-col>
            <!-- <v-col cols="12" class="text-center mt-n5">
              <v-btn color="#2e2e2e"
                ><span style="color: #fff">เปลี่ยนรูปโปรไฟล์</span>
              </v-btn>
            </v-col> -->
            <!-- <v-col cols="12" class="text-center mt-n5">
              <span>เลขที่สมาชิก: TEST</span>
            </v-col> -->

            <v-col cols="12">
              <span style="color: #4ca3ad">ชื่อ-นามสกุล </span>&emsp;
              <v-btn small color="#2e2e2e" @click="editName()"
                ><v-icon size="16px" style="color: #fff"
                  >mdi-pencil-outline</v-icon
                >
                <span style="color: #fff">แก้ไขชื่อ - นามสกุล</span>
              </v-btn>
            </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">ชื่อ <br /></span>
              <span>{{ userData.user ? userData.user.firstName : "" }}</span>
            </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">นามสกุล <br /></span>
              <span>{{ userData.user ? userData.user.lastName : "" }}</span>
            </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">เบอร์โทรศัพท์ <br /></span>
              <span>{{ userData.user ? userData.user.telNumber : "" }}</span>
            </v-col>
            <!-- <v-col cols="6" class="">
              <span style="font-weight: bold">อีเมล์ <br /></span>
              <span>{{ userData.user.email ? userData.user.email : "-" }}</span>
            </v-col> -->
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="mt-n14 pa-10"
        v-if="userData.user.telCode != null"
      >
        <v-card style="width: 800px; border: 0px; margin: 0 auto" class="pa-4"
          ><v-card-title
            ><span style="color: #4ca3ad">ลิ้งค์ชวนเพื่อน</span>
          </v-card-title>
          <v-row class="pa-6">
            <span>{{ linkAffiliation }}</span
            >&emsp;
            <v-btn small color="#2e2e2e" @click="copyURLAff()">
              <v-icon size="16px" style="color: #fff">mdi-copy-outline</v-icon>
              <span style="color: #fff">คัดลอก</span>
            </v-btn>
          </v-row>
          <!-- <v-card-title
            ><span style="color: #4ca3ad">รายได้ชวนเพื่อน</span>
          </v-card-title>
          <v-card class="text-center aff-card ma-2">ยอดสะสม 0 บาท</v-card> -->
        </v-card>
      </v-col>
      <!-- <v-col cols="12" class="mt-n14 pa-10">
        <v-card style="width: 800px; border: 0px; margin: 0 auto" class="pa-4"
          ><v-card-title
            ><span style="color: #4ca3ad">ลิ้งค์ชวนเพื่อน</span>
          </v-card-title>
          <v-row class="pa-6">
            <span>link aff</span>&emsp;
            <v-btn small color="#2e2e2e"
              ><v-icon size="16px" style="color: #fff">mdi-copy-outline</v-icon>
              <span style="color: #fff">คัดลอก</span>
            </v-btn>
          </v-row>
          <v-card-title
            ><span style="color: #4ca3ad">รายได้ชวนเพื่อน</span>
          </v-card-title>
          <v-card class="text-center aff-card ma-2">ยอดสะสม 0 บาท</v-card>
        </v-card>
      </v-col>
      <v-col cols="12" class="mt-n14 pa-10">
        <v-card style="width: 800px; border: 0px; margin: 0 auto" class="pa-4"
          ><v-card-title
            ><span style="color: #4ca3ad">อัพเดทสถานะคำสั่งซื้อ</span>&emsp;
            <v-btn small color="#2e2e2e"
              ><v-icon size="16px" style="color: #fff"
                >mdi-pencil-outline</v-icon
              >
              <span style="color: #fff">แก้ไข</span>
            </v-btn>
          </v-card-title>
          <v-row class="pa-6 mt-n10">
            <v-radio-group v-model="receiveNews" column>
              <v-radio
                label="รับผ่าน SMS"
                value="sms"
                :disabled="isUpdate"
              ></v-radio>
              <v-radio
                label="รับผ่าน Line"
                value="line"
                :disabled="isUpdate"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </v-card>
      </v-col> -->
      <v-col cols="12" class="mt-n14 pa-10">
        <v-card style="width: 800px; border: 0px; margin: 0 auto" class="pa-4"
          ><v-card-title
            ><span style="color: #4ca3ad"
              >ข้อมูลบัญชี
              <span style="font-weight: 600"><u>(รับเงินรางวัล)</u></span></span
            >&emsp;
            <v-btn small color="#2e2e2e" @click="addBankAccount()"
              ><v-icon size="16px" style="color: #fff"
                >mdi-pencil-outline</v-icon
              >
              <span style="color: #fff">แก้ไข</span>
            </v-btn>
          </v-card-title>
          <v-row class="pa-6 mt-n10">
            <v-col cols="6" class="">
              <span style="font-weight: bold">ชื่อบัญชี <br /></span>
              <span>{{ userData.user ? userData.user.accountName : "" }}</span>
            </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">ชื่อธนาคาร <br /></span>
              <span>{{ userData.user ? userData.user.bankName : "" }}</span>
            </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">เลขที่บัญชี <br /></span>
              <span>{{ userData.user ? userData.user.bankNo : "" }}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" class="mt-n14 pa-10">
        <v-card style="width: 800px; border: 0px; margin: 0 auto" class="pa-4"
          ><v-card-title
            ><span style="color: #4ca3ad">ข้อมูลที่อยู่</span>&emsp;
            <v-btn small color="#2e2e2e"
              ><v-icon size="16px" style="color: #fff"
                >mdi-pencil-outline</v-icon
              >
              <span style="color: #fff">แก้ไข</span>
            </v-btn>
          </v-card-title>
          <v-row class="pa-6 mt-n10">
            <v-col cols="6" class="">
              <span style="font-weight: bold">ที่อยู่ <br /></span>
              <span>{{ userData.address ? userData.address : "-" }}</span>
            </v-col>
            <v-col cols="6" class=""> </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">จังหวัด <br /></span>
              <span>{{ userData.province ? userData.province : "-" }}</span>
            </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">รหัสไปรษณีย์ <br /></span>
              <span>{{ userData.zipcode ? userData.zipcode : "-" }}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col> -->
    </v-row>
    <DialogBankAccount
      :dialog="dialogBankAccount"
      @closeDialogBankAccount="closeDialogBankAccount"
    />
    <DialogEditName
      :dialog="dialogEditName"
      @closeDialogEditName="closeDialogEditName"
    />
  </div>
</template>

<script>
import { Encode, Decode } from "@/services";
import DialogBankAccount from "./Dialog/DialogBankAccount.vue";
import DialogEditName from "./Dialog/DialogEditName.vue";

export default {
  components: {
    DialogBankAccount,
    DialogEditName,
  },

  data: () => ({
    dialogBankAccount: false,
    dialogEditName: false,
    checkbox1: true,
    checkbox2: true,

    receiveNews: null,
    isUpdate: true,
    userData: [],
    currentURL: window.location.href.replace(/\profile$/, ""),
    linkAffiliation: "",
  }),

  created() {
    const encodedUserLogin = localStorage.getItem("RAUser");
    if (encodedUserLogin !== null) {
      const decodedUserLogin = Decode.decode(encodedUserLogin);
      this.userData = JSON.parse(decodedUserLogin);

      this.receiveNews = this.userData.receiveNews;
    }
    console.log("this.userData", this.userData);
    this.userData.user.telCode !== null
      ? (this.linkAffiliation =
          this.currentURL + "?refNumber=" + this.userData.user.telCode)
      : "";
  },
  methods: {
    copyURLAff() {
      navigator.clipboard.writeText(this.linkAffiliation);
      this.$swal.fire({
        icon: "success",
        title: "คัดลอกสำเร็จ",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    goToLine() {
      window.open("https://line.me/ti/p/~@016hklcg");
    },
    async closeDialogBankAccount() {
      this.dialogBankAccount = false;
      this.$router.go(0);
    },
    async closeDialogEditName() {
      this.dialogEditName = false;
      this.$router.go(0);
    },

    addBankAccount() {
      this.dialogBankAccount = true;
    },
    editName() {
      this.dialogEditName = true;
    },
  },
};
</script>

<style scoped>
.single-line-row {
  display: flex;
  align-items: center;
}

.responsive-img {
  width: 120px;
  max-width: 50%;
  height: 120px;
  border: 1px solid #008080;
  border-radius: 50%;
}

.aff-card {
  margin-top: 15px;
  padding: 7px;
  background: rgb(109, 119, 141);
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: no-drop;
  flex-grow: 1;
}
</style>

